import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.visible,
    title: _ctx.title,
    footer: null,
    width: 800
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form, {
        labelCol: { span: 5 },
        wrapperCol: { span: 18 },
        onSubmit: _cache[9] || (_cache[9] = _withModifiers(($event: any) => (_ctx.$emit('save', _ctx.form)), ["prevent"])),
        id: "myForm"
      }, {
        default: _withCtx(() => [
          _cache[12] || (_cache[12] = _createElementVNode("h3", null, "Account Details", -1)),
          _cache[13] || (_cache[13] = _createElementVNode("hr", null, null, -1)),
          _createVNode(_component_a_form_item, { label: "Username" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                type: "text",
                value: _ctx.form.user_name,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.user_name) = $event)),
                id: "user_name",
                required: ""
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, { label: "Email" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                type: "email",
                value: _ctx.form.email,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.email) = $event)),
                id: "email",
                required: ""
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          (_ctx.isNew == true)
            ? (_openBlock(), _createBlock(_component_a_form_item, {
                key: 0,
                label: "Password"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    type: "password",
                    value: _ctx.form.password,
                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.password) = $event)),
                    id: "password",
                    required: ""
                  }, null, 8, ["value"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_a_form_item, { label: "Role" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select, {
                value: _ctx.form.role_id,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.role_id) = $event)),
                id: "role_id",
                required: "",
                "show-search": "",
                filterOption: _ctx.filterOption
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roles.data, (item) => {
                    return (_openBlock(), _createBlock(_component_a_select_option, {
                      key: item.value
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.label), 1)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value", "filterOption"])
            ]),
            _: 1
          }),
          _cache[14] || (_cache[14] = _createElementVNode("h3", null, "Personal Information", -1)),
          _cache[15] || (_cache[15] = _createElementVNode("hr", null, null, -1)),
          _createVNode(_component_a_form_item, { label: "Salutation" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                type: "text",
                value: _ctx.form.salutation,
                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.salutation) = $event)),
                id: "salutation"
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, { label: "First Name" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                type: "text",
                value: _ctx.form.first_name,
                "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.first_name) = $event)),
                id: "first_name",
                required: ""
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, { label: "Middle Name" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                type: "text",
                value: _ctx.form.middle_name,
                "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.middle_name) = $event)),
                id: "middle_name"
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, { label: "Last Name" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                type: "text",
                value: _ctx.form.last_name,
                "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.last_name) = $event)),
                id: "last_name",
                required: ""
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, { "wrapper-col": { span: 18, offset: 5 } }, {
            default: _withCtx(() => [
              _createVNode(_component_a_space, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_button, {
                    key: "back",
                    onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('cancel')))
                  }, {
                    default: _withCtx(() => _cache[10] || (_cache[10] = [
                      _createTextVNode(" Cancel ")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_a_button, {
                    key: "submit",
                    type: "primary",
                    htmlType: "submit",
                    loading: _ctx.loading
                  }, {
                    default: _withCtx(() => _cache[11] || (_cache[11] = [
                      _createTextVNode(" Submit ")
                    ])),
                    _: 1
                  }, 8, ["loading"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["visible", "title"]))
}